import React from "react";
import { graphql } from "gatsby";

export default function Menu({ data }) {
  return <></>;
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query {
    map: allSanityAbout {
      nodes {
        map {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
      }
    }
  }
`;
